body,
button,
input {
    font-family: 'Calibri', sans-serif;
    margin: 0; /* Added to remove default margin */
    padding: 0;
}

.top-bar {
    background-color: #2c3e50;
    color: white;
    padding: 10px 0;
    text-align: center;
}

.top-bar button {
    background-color: #3498db;
    color: white;
    padding: 8px 16px;
    margin: 0 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.top-bar button:hover {
    background-color: #2980b9;
}

.top-bar button.active {
    background-color: #1abc9c;
    color: white;
}

.chat-screen {
    flex: 1; /* Expand to fill available space */
    border: 1px solid #ecf0f1;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
    background-color: rgb(255, 255, 255);
    width: 100%; /* Set width to 100% */
}

.messages {
    height: calc(100% - 20%); /* Adjusted height, leaving space for the input area */
    overflow-y: auto;
    padding: 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #bdc3c7;
}


.message-container {
    display: block; /* Change display to block */
}

.message {
    display: inline-block;
    padding: 10px;
    margin: 5px 0;
    border-radius: 18px;
    line-height: 1.4;
    font-size: 14px;
    max-height: 100%;
    background-color: #ffffff;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    word-break: break-all; /* Allow breaking of words if needed */
    float: right; /* Align message bubbles to the left */
    clear: both;
}


.message.user {
    background-color: #3498db;
    color: white;
    margin-left: auto;
    text-align: right;
    align-self: flex-end;
    font-size: 18px;
}

.message.bot {
    background-color: #c1c1c1;
    color: white;
    margin-right: auto;
    text-align: left;
    align-self: flex-start;
    font-size: 18px;
}

.message.flow {
    background-color: #ffffff;
    color: black;
    margin-right: auto;
    text-align: left;
    align-self: flex-start;
    font-size: 20px;
    border: 3px solid #bdc3c7;
    word-wrap: break-word;
    white-space: pre-wrap;

}

.input-area {
    padding: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Add space between elements */
    border-top: 1px solid #bdc3c7;
}

.input-area input {
    flex-grow: 1;
    padding: 10px;
    border: 2px solid #bdc3c7;
    border-radius: 18px;
    font-size: 16px;
}

.input-area button {
    background-color: #ffffff;
    color: black;
    padding: 10px 16px;
    margin-left: 10px;
    border: 2px solid #bdc3c7;
    border-radius: 18px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.input-area button:hover {
    background-color: #ffffff;
}

.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%; /* Set width to 100% of the viewport width */
    height: 85vh; /* Set height to 100% of the viewport height */
}